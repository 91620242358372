@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');

:root {
    --primary-font: "Poppins",sans-serif;
    --secondary-font: "Roboto",sans-serif;
}

body {
    font-family: var(--primary-font);
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  
  #audio-features, #refresher-features, #pass-plus, #home{
    animation: fadeIn 1.7s ease-in-out;
  }